<template>
  <v-dialog
    v-model="isOpen"
    max-width="1000"
    @input="handleClose"
  >
    <v-card>
      <v-card-title class="headline pb-10">
        <div class="columns">
          <div class="column">
            <h2 class="title is-3">Создать домен</h2>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-layout column>
            <b-field label="Введите домен">
              <b-input v-model="currentDomain.name"></b-input>
            </b-field>
          </v-layout>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">Закрыть</v-btn>
        <v-btn text color="primary" @click="createDomain()">Добавить</v-btn>
      </v-card-actions>
      <overlay-loader :show="isLoading"></overlay-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import OverlayLoader from '@/app/shared/components/app-loader/Loader';

export default {
  name: 'DomainDetailsModal',
  components: {
    OverlayLoader,
  },
  data() {
    return {

    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.IS_CURRENT_DOMAIN_LOADING;
    },
    isOpen() {
      return this.$store.getters.IS_DOMAIN_DETAILS_MODAL_OPENED;
    },
    currentDomain: {
      get() {
        return this.$store.getters.GET_CURRENT_DOMAIN;
      },
      set(value) {
        this.$store.commit("SET_CURRENT_DOMAIN", value);
      },
    },
  },
  methods: {
    close() {
      this.$store.dispatch("CURRENT_DOMAIN", { });
      this.$store.dispatch('DOMAIN_DETAILS_MODAL', false);
    },
    createDomain(){
      this.$store.dispatch('CREATE_DOMAIN', this.currentDomain)
        .then(() => {
          this.$toast.success("Шаблон успешно добавлен");
          this.$store.dispatch("CURRENT_DOMAIN", { });
          this.$store.dispatch("GET_DOMAINS");
          this.$store.dispatch('DOMAIN_DETAILS_MODAL', false);
        })
        .catch(() => {
          this.$toast.error("Ошибка при добавлении домена");
        });
    },
    handleClose(isOpen) {
      if (!isOpen) {
        this.close();
      }
    }
  },
};
</script>
